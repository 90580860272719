.modal-button {
  height: 6rem;

  font-size: 1.75rem;
  font-weight: bold;

  border: 1px solid #222222;
  border-radius: 4px;

  background-color: #333333;

  transition: all 0.2s;

  cursor: pointer;
}

.modal-button:hover {
  background-color: #2a2a2a;
}

.modal-button:active {
  transform: scale(0.97);
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.24);
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .modal-button {
    font-size: 1.5rem;
    padding: 1rem;
  }
}
