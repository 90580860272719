.vertical-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 9rem;
  padding-top: 4rem;
  background-color: rgba(29, 29, 29, 0.6);
}

.vertical-menu > button:not(:last-child) {
  margin-bottom: 1rem;
}

.vertical-menu__author {
  margin-top: auto;
  margin-bottom: 1rem;

  font-size: 1.25rem;
  opacity: 0.5;

  transition: all 0.1s;
}

.vertical-menu__author > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.vertical-menu__author:hover {
  opacity: 1;
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .vertical-menu {
    top: calc(100% - 7.5rem);
    z-index: 50;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 1rem;
    height: 8rem;
    width: 100%;
    background-color: rgba(29, 29, 29, 0.6);
    overflow: hidden;
  }

  .vertical-menu > button:not(:last-child) {
    margin-bottom: 0;
  }

  .vertical-menu__author {
    display: none;
    visibility: hidden;
  }
}
