.desktop {
  position: fixed;
  height: 100%;
  width: 100vw;

  background-image: url("/public/images/ubuntu-desktop-background-1080p.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  object-fit: contain;

  overflow: hidden;
}

/* Small BP */

@media (min-width: 56.25em) {
  .desktop {
    background-image: url("/public/images/ubuntu-desktop-background-hd.jpg");
  }
}
