/* Variables */

/*

Breakpoints
$bp-max: 125em; // 2000px
$bp-largest: 87.5em; // 1400px
$bp-large: 68.75em; // 1100px
$bp-medium: 62.5em; // 1000px
$bp-small: 50em; // 800px
$bp-smaller: 40.625em; // 650px
$bp-smallest: 31.25em; // 500px

*/

/* CSS Variables */

:root {
  /* Fonts */
  --default-font-style: 'Ubuntu', sans-serif;
  --default-font-color: #ffffff;

  /* Colours */
  --color-dark-grey: #1d1d1d;
  --color-light-grey: #343434;
  --color-orange-highlight: #eb6637;
  --color-light-orange-highlight: #fe7c4d;
  --color-purple: #924d8b;

  /* Backgrounds */
  --background-light-white: rgba(#ffffff, 0.1);

  /* Breakpoints */
  /* --bp-largest: 87.5em; // 1400px */
  /* --bp-medium: 81.25em; 1300px */
  /* --bp-small: 56.25em; 900px */
  /* --bp-smallest: 37.5em 600px */
}

/* Reset */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  position: relative;
  box-sizing: border-box;
  font-family: var(--default-font-style);
  line-height: 1;
  color: var(--default-font-color);
  overflow-x: hidden;
}

button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.5rem;
  margin: 1rem 0;
}

h3 {
  font-size: 2rem;
  margin: 1rem 0;
}

h4 {
  font-size: 1.75rem;
  margin: 1rem 0;
}

p {
  font-size: 1.8rem;
  margin: 0.75rem 0;
  line-height: 1.5;
}

ul {
  font-size: 1.8rem;
  margin-left: 2rem;
  line-height: 1.5;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  color: inherit;
  text-decoration: none;
}

/* App */

a {
  text-decoration: underline;
  color: var(--color-light-orange-highlight);
}

a:hover {
  color: var(--color-orange-highlight);
}

.App {
  height: 100vh;
  width: 100vw;
}

span.highlighted-text {
  color: var(--color-orange-highlight);
  font-weight: bold;
}

/* Smallest BP */

@media (max-width: 37.5em) {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.6rem;
  }
}
