@keyframes moveInFromBottomLarge {
  0% {
    opacity: 0.25;
    top: 100%;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    top: 5%;
    opacity: 0.75;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: 10%;
  }
}

@keyframes moveInFromBottomSmall {
  0% {
    opacity: 0.25;
    top: 100%;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    top: 7.5%;
    opacity: 0.75;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: 12.5%;
  }
}

.modal {
  position: absolute;
  left: calc(10% + 4.5rem);
  top: 10%;
  z-index: 20;

  height: 85vh;
  width: 80%;

  border-radius: 5px;
  border: 2px solid #271e20;

  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);

  background-color: #343434;

  overflow: hidden;

  animation-name: moveInFromBottomLarge;
  animation-duration: 0.65s;
}

.modal--small {
  position: absolute;
  top: 12.5%;
  left: calc(10% + 4.5rem);
  z-index: 20;

  height: fit-content;
  width: 80%;

  border-radius: 5px;
  border: 2px solid #271e20;

  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);

  background-color: #343434;

  overflow: hidden;

  animation-name: moveInFromBottomSmall;
  animation-duration: 0.65s;
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4rem;
  width: 100%;

  padding: 0 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 3px solid #252525;
  border-top: 3px solid #1a1a1a;

  background-color: #1d1d1d;
}

.modal__header-text {
  font-size: 1.5rem;
  user-select: none;
  pointer-events: none;
  margin-left: auto;
}

.modal__header-buttons {
  display: flex;
  margin-left: auto;
}

.modal__header-buttons > button:not(:last-child) {
  margin-right: 0.5rem;
}

.modal__body {
  height: 100%;

  padding: 5rem 0;

  background-color: #3e3e3e;

  overflow: auto;
}

.modal__body-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 90%;

  margin: 0 auto;
}

/* Small BP */

@media (max-width: 56.25em) {
  .modal {
    animation: none;
  }

  .modal--small {
    top: 5%;
  }

  .modal__body {
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .modal {
    top: 5%;
    left: 2.5%;
    height: 75vh;
    width: 95%;
  }

  .modal--small {
    top: 5%;
    left: 2.5%;
    height: 75vh;
    width: 95%;
  }
}
