.contact-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr 3fr 1fr;
  gap: 1rem;

  margin-top: 2rem;

  width: 100%;
}

.contact-form > ::placeholder {
  color: #909090;
}

.contact-form > input,
textarea {
  font-size: 2rem;
  font-family: var(--default-font-style);
  font-weight: 200;
  padding-top: 0.75rem;
  padding-left: 1rem;

  outline: 0;
  border: none;
  border-bottom: 0.5rem solid transparent;

  color: white;
  background-color: #2f2f2f;

  transition: all 0.2s;
}

.contact-form > input:focus,
textarea:focus {
  border-bottom: 0.5rem solid var(--color-orange-highlight);
}

.contact-form > textarea {
  resize: none;
}

.contact-form__name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.contact-form__email {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.contact-form__subject {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.contact-form__message {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
}

.contact-form__notification {
  width: 75%;

  align-self: center;
  justify-self: center;

  font-size: 1.75rem;
  font-weight: bold;
  opacity: 0.7;
  line-height: 1.5;

  color: var(--color-orange-highlight);
}

.contact-form__notification--hidden {
  display: none;
}

.validated {
  border-bottom: 0.5rem solid rgb(42, 179, 42) !important;
}

/* Small BP */

@media (max-width: 56.25em) {
  .contact-form {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 3fr 1fr;
    gap: 1rem;

    margin-top: 2rem;
  }

  .contact-form > input,
  textarea {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }

  .contact-form__name {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .contact-form__email {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .contact-form__subject {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .contact-form__message {
    grid-column: 1 / 2;
    grid-row: 4 / 6;
  }

  .contact-form__notification {
    width: 100%;
    height: 8rem;
  }
}
