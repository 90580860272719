.modal-header-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.5rem;
  width: 2.5rem;

  border-radius: 50%;

  overflow: hidden;

  transition: all 0.2s;
}

.modal-header-button:hover {
  background-color: #4a4a4a;
}

.modal-header-button > img {
  height: 1.7rem;
  width: 1.7rem;
}

.modal-header-button:hover > img {
  transform: scale(1.1);
}

.modal-header-button--close:hover {
  background-color: var(--color-orange-highlight);
}
