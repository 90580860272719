.clock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 15rem;
  padding-top: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
  cursor: default;

  transition: all 0.1s;
}

.clock:focus,
.clock:active {
  border-bottom: 3px solid var(--color-orange-highlight);
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .clock {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 13rem;
    padding-left: 0;
    padding-right: 0;
  }
}