.modal-icon-list {
  display: flex;
  align-items: center;

  width: 100%;

  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;

  background: linear-gradient(
    68deg,
    rgba(42, 42, 42, 1) 40%,
    rgba(0, 212, 255, 0) 85%
  );

  overflow-x: auto;
}

.modal-icon-list > :not(:first-child) {
  margin-left: 1.75rem;
}

.modal-icon-list__img {
  height: 7.5rem;
  width: 7.5rem;

  border-radius: 10%;

  transition: all 0.2s;
}

.modal-icon-list__img:hover {
  transform: scale(1.1);
}
