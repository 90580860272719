.square-block-button {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 7.5rem;
  height: 10rem;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.square-block-button:hover > .square-block-button__label {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 1;
}

.square-block-button:hover > .square-block-button__icon {
  transform: scale(1.05);
}

.square-block-button--highlighted {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 7.5rem;
  height: 10rem;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;

  background-color: rgba(255, 255, 255, 0.1);
}

.square-block-button--highlighted > .square-block-button__label--highlighted {
  position: absolute;
  top: 85%;
  left: 50%;
  opacity: 1;
}

.square-block-button--highlighted > .square-block-button__icon {
  transform: scale(1.05);
}

.square-block-button__icon {
  height: 6rem;
  width: 6rem;
  pointer-events: none;
}

.square-block-button__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.5rem;
  opacity: 0;
  pointer-events: none;

  transition: all 0.35s ease-out;
}

.square-block-button__label--highlighted {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;

  font-size: 1.5rem;
  opacity: 0;
  pointer-events: none;
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .square-block-button {
    padding: 0;
    padding-top: 1rem;
    width: 25%;
  }

  .square-block-button--highlighted {
    width: 25%;
  }

  .square-block-button__icon {
    height: 4rem;
    width: 4rem;
  }

  .square-block-button--highlighted > .square-block-button__label--highlighted {
    position: absolute;
    top: 65%;
    left: 50%;
    opacity: 1;
  }
}
