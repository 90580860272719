.portfolio-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.portfolio-filter__btn {
  padding: 2rem;
  margin: 0 1rem;

  background-color: #333333;

  border-radius: 4px;
  border-bottom: 0.4rem solid transparent;

  transition: all 0.2s;
}

.portfolio-filter__btn:hover {
  background-color: #2a2a2a;
}

.portfolio-filter__btn:hover > img {
  transform: translateY(-3%);

  transition: all 0.2s ease-in;
}

.portfolio-filter__btn--highlighted {
  transform: translateY(-3%);

  border-bottom: 0.4rem solid var(--color-orange-highlight);

  background-color: #2a2a2a;
}

.portfolio-filter__btn--highlighted > img {
  transform: translateY(-3%);
}

.portfolio-filter__img {
  height: 8rem;
  width: 8rem;

  pointer-events: none;
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .portfolio-filter {
    width: 80%;
    margin: 0.5rem auto;
  }

  .portfolio-filter__btn {
    padding: 1rem;
  }

  .portfolio-filter__img {
    height: 4rem;
    width: 4rem;
  }
}
