.portfolio-project-list {
  width: 100%;

  border-radius: 4px;
}

.portfolio-project-list__project {
  position: relative;

  display: flex;
  justify-content: space-between;

  padding: 1rem;
  margin: 1rem 0;

  background-color: #2a2a2a;

  border-radius: 4px;
}

.portfolio-project-list__project-logo {
  position: absolute;
  top: 1rem;
  right: 1rem;

  height: 3rem;
  width: 3rem;
}

.portfolio-project-list__preview {
  align-self: center;
  width: 45%;
}

.portfolio-project-list__preview > img {
  width: 100%;

  border: 1px solid black;
}

.portfolio-project-list__project-info {
  display: flex;
  flex-direction: column;
  width: 52.5%;
}

.portfolio-project-list__project-info__release-date {
  font-size: 1.25rem;
}

.portfolio-project-list__button-container {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
}

.portfolio-project-list__button-container > button {
  width: 14rem;
}

.portfolio-project-list__button-container > button:not(:last-child) {
  margin-right: 1rem;
}

/* Medium BP */

@media (max-width: 81.25em) {
  .portfolio-project-list__project {
    flex-direction: column;
    justify-content: space-between;
  }

  .portfolio-project-list__preview {
    width: calc(100% - 4rem);
    align-self: flex-start;
  }

  .portfolio-project-list__project-info {
    width: 100%;
  }

  .portfolio-project-list__button-container {
    position: relative;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
}

/* Smallest BP */

@media (max-width: 37.5em) {
  .portfolio-project-list__button-container {
    flex-direction: column;
    justify-content: center;
  }
  .portfolio-project-list__button-container > button:not(:first-child) {
    margin-top: 1rem;
  }
}
