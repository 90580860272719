.top-bar {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  background-color: var(--color-dark-grey);
  color: #ffffff;
  font-size: 1.5rem;
  user-select: none;
}

.top-bar__logo {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 11rem;

  margin: auto 0;

  font-family: "Anton", sans-serif;
  letter-spacing: 0.4rem;
  opacity: 0.9;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;

  transition: all 0.1s;
}

.top-bar__logo:focus,
.top-bar__logo:active {
  border-bottom: 3px solid var(--color-orange-highlight);
  border-top: 3px solid transparent;
}

.top-bar__version {
  padding-top: 0.6rem;
  font-size: 0.8rem;
  letter-spacing: 0.15rem;
}